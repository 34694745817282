<template>
  <div>
    <div class="box_top" style="width: 100%;">
      <templateHeader title="大中型水利水电工程主要特征" />
      <div class="export_box">
        <el-button type="primary" size="mini" plain icon="el-icon-download" @click="exportExcel">导出</el-button>
        <el-button type="primary" size="mini" icon="el-icon-upload2" @click="importExcel">导入</el-button>
      </div>
    </div>

    <el-row :gutter="10">
      <el-col :span="3">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </el-col>

      <el-col :span="21">
        <div class="table_right">
          <el-table
            v-loading="loading"
            ref="table"
            :key="Math.random()"
            :data="listData"
            style="width: 100%;position:absolute;"
            height="640px"
          >
            <el-table-column align="center" fixed type="index" label="序号" width="50">
              <template slot-scope="scope">
                <span>
                  {{ (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index + 1 }}
                </span>
              </template>
            </el-table-column>

            <el-table-column align="center" fixed prop="approvalDate" width="150" label="工程批（核）准时间">
              <template slot-scope="scope">
                {{ scope.row.approvalDate ? timeFormate(scope.row.approvalDate, 'YYYY-MM') : ' ' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              fixed
              prop="projectName"
              width="110"
              style="word-break: normal"
              label="工程名称"
            >
              <template slot-scope="scope">
                <el-tooltip placement="top-start">
                  <div slot="content" class="text_warp">
                    {{ scope.row.projectName }}
                  </div>
                  <div style="color: #000" class="text_els">
                    {{ scope.row.projectName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed prop="county" width="150" label="涉及县（市、区）"></el-table-column>

            <el-table-column
              align="center"
              prop="projectType"
              width="190"
              label="工程类型（水利/水电）"
            ></el-table-column>
            <el-table-column align="center" prop="sizeType" width="150" label="类型（大型/中型）"></el-table-column>

            <el-table-column align="center" label="工程属性">
              <el-table-column align="center" prop="projectStatus" width="80" label="已建">
                <template slot-scope="scope">
                  <div v-if="scope.row.projectStatus === '已建'">
                    {{ scope.row.projectStatus }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="projectStatus" width="80" label="在建">
                <template slot-scope="scope">
                  <div v-if="scope.row.projectStatus === '在建'">
                    {{ scope.row.projectStatus }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column align="center" prop="totalCapacity" width="140" label="总库容（亿m³）"></el-table-column>
            <el-table-column
              align="center"
              prop="totalInstalledPower"
              width="140"
              label="总装机（兆瓦）"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="annualElectricityGeneration"
              width="150"
              label="年发电量（亿kwh）"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="annualWaterSupply"
              width="140"
              label="年供水量（万m³）"
            ></el-table-column>
            <el-table-column align="center" prop="startDate" width="140" label="开工时间（年月）">
              <template slot-scope="scope">
                {{ scope.row.startDate ? timeFormate(scope.row.startDate, 'YYYY年MM月') : ' ' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reservoirAcceptanceDate" width="170" label="蓄水验收时间（年月）">
              <template slot-scope="scope">
                {{
                  scope.row.reservoirAcceptanceDate ? timeFormate(scope.row.reservoirAcceptanceDate, 'YYYY年MM月') : ' '
                }}
              </template>
            </el-table-column>

            <el-table-column align="center" prop="completionAcceptanceDate" width="170" label="竣工验收时间（年月）">
              <template slot-scope="scope">
                {{
                  scope.row.completionAcceptanceDate
                    ? timeFormate(scope.row.completionAcceptanceDate, 'YYYY年MM月')
                    : ' '
                }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="approvalDocumentNumber"
              width="190"
              style="word-break: normal"
              label="工程批（核）准文号"
            >
              <template slot-scope="scope">
                <el-tooltip placement="top-start">
                  <div slot="content" class="text_warp">
                    {{ scope.row.approvalDocumentNumber }}
                  </div>
                  <div style="color: #000" class="text_els">
                    {{ scope.row.approvalDocumentNumber }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column align="center" label="是否纳入后期扶持范围">
              <el-table-column align="center" prop="resettlementOfMigrants" width="120" label="搬迁安置居民">
                <template slot-scope="scope">
                  {{ scope.row.resettlementOfMigrants ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="resettlementWithoutRelocation"
                width="160"
                label="不搬迁只生产安置移民"
              >
                <template slot-scope="scope">
                  {{ scope.row.resettlementWithoutRelocation ? '是' : '否' }}
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column align="center" width="120" style="word-break: normal" prop="remarks" label="备注">
              <template slot-scope="scope">
                <el-tooltip placement="top-start">
                  <div slot="content" class="text_warp">
                    {{ scope.row.remarks }}
                  </div>
                  <div style="color: #000" class="text_els">
                    {{ scope.row.remarks }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <div>
            <Pagination :isHomePage2="true" v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
          </div>
        </div>
      </el-col>
    </el-row>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
  </div>
</template>

<script>
import { _getLevelRegion } from '@/api/keepHome'

import templateHeader from '@/components/templateView/templateHeader.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import templateTree from '@/components/templateView/templateTree.vue'
import Pagination from '@/components/Pagination'
import {
  _EngineeringPage,
  _EngineeringExport
} from '@/api/modular/postImmigrationService/HomePage/hydropowerProjects.js'

export default {
  name: 'HcYmhfIndex',

  data() {
    return {
      // 分页参数
      loading: false,
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      dck: [],
      listData: [],
      treeData: [],
      defaultProps: {
        children: 'childRegions',
        label: 'name'
      },
      OrgId: null,
      year: null,
      years: [],
      OrgPids: null,
      downloadObj: {},
      IsLevel: false
    }
  },
  components: {
    templateHeader,
    Pagination,
    tempalteImport,
    templateTree
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  watch: {
    listData: {
      deep: true,
      handler: function() {
        this.$nextTick(() => {
          this.$refs.table.doLayout()
        })
      }
    }
  },

  methods: {
    setYears(value, years) {
      this.year = value
      this.years = years
      this.init()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    init() {
      this.getLevelRegion()
      this.getPage()
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    receiveId(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      // this.treeData = [...this.treeData]
      if (value) {
        this.dck = JSON.parse(JSON.stringify([value]))
      } else {
        this.dck = []
      }
      this.downloadObj = obj
      this.OrgId = value
      this.OrgPids = obj?.pids
      this.getPage()
    },
    refreshTable() {
      this.getPage()
    },
    refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm

      let params = {
        year: this.year,
        OrgId: this.OrgId,
        pageNo: currentPage,
        pageSize: pageSize,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      _EngineeringPage(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows
        }
      })
    },
    exportExcel() {
      console.log(this.downloadObj, '====this.downloadObj===')
      let params = {
        year: this.year,
        IsLevel: this.IsLevel,
        OrgId: this.OrgId, //行政区域编号
        OrgName: this.downloadObj.name, //行政区域名称
        OrgPids: this.downloadObj.pids //行政区域编号path
      }
      _EngineeringExport(params).then(res => {
        if (res.status == 200) {
          this.downloadfile(res)
        } else {
          this.$message.error('导出失败')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.year, this.years, '1-2-1')
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// @import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';

.box_top {
  display: flex;
  justify-content: space-between;
}

.table_right {
  max-width: 100%;
  position: relative;
  height: 700px;
}

::v-deep .el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto;
}
</style>
